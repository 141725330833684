/**
  颜色变量
**/
.content {
  display: flex;
  align-items: center;
  min-height: 77vh;
}
.content > div {
  flex: 1;
}
.content > div .top {
  color: #444e59;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 24px;
}
.content > div .bottom {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  line-height: 36px;
  opacity: 0.45;
}
