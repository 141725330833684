/**
  颜色变量
**/
.msgModal_box {
  min-height: 300px;
  width: 100%;
}
.msgModal_box .msgModal_b_r {
  margin-left: 16px;
  padding-right: 16px;
}
.lookMsg_box {
  display: flex;
  padding-top: 24px;
}
.lookMsg_box .lookMsg_box_l {
  width: 27%;
  margin-top: 48px;
  padding-left: 24px;
}
.lookMsg_box .lookMsg_box_r {
  width: 73%;
  margin-left: 48px;
  padding-right: 48px;
}
.msg_r_title {
  width: 100%;
  font-weight: 600;
  font-size: 24px;
  text-align: center;
}
.msg_r_top {
  display: flex;
  border-bottom: 1px solid #ccc;
  padding-bottom: 8px;
  width: 100%;
  color: #999;
  margin-top: 16px;
}
.msg_r_top .msg_r_t_1 {
  width: 43%;
  text-align: left;
  display: flex;
}
.msg_r_top .msg_r_t_2 {
  flex: 23%;
  text-align: center;
}
.msg_r_top .msg_r_t_3 {
  flex: 33%;
  text-align: right;
}
.msg_r_content {
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 16px;
  min-height: 200px;
  background-color: #fafafa;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: auto;
}
.msg_r_fj {
  margin-bottom: 16px;
  height: 120px;
  overflow: hidden;
  overflow-y: auto;
  min-height: 80px;
}
.msg_r_fj .msg_r_fj_item {
  margin-bottom: 12px;
  background-color: #fafafa;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
}
.msg_btn {
  width: 100%;
  text-align: center;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.msg_btn .msg_btn_div {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.msg_read_box {
  background-color: #fafafa;
  padding: 8px;
  margin-bottom: 16px;
  height: 35px;
  overflow: hidden;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  width: 100%;
}
.msg_read_box .msg_read_box_l {
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.msg_read_box .msg_read_box_l .msg_read_box_l_item {
  margin-right: 12px;
  margin-bottom: 16px;
}
.msg_read_box .msg_read_box_r {
  width: 5%;
  text-align: right;
  color: #1890ff;
}
.msg_read_hidden {
  height: 320px;
  overflow: hidden;
  overflow-y: auto;
}
.msg_left_item {
  color: #1890ff;
  border-bottom: 1px solid #ccc;
  width: 95%;
  margin-bottom: 16px;
  padding-bottom: 8px;
  cursor: pointer;
}
