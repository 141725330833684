/**
  颜色变量
**/
.admin_route_page {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.arp_edit_modal {
  width: 100%;
  height: 600px;
  overflow: auto;
  padding-right: 20px;
}
.admin_router_page_bottom {
  background-color: #fff;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}
