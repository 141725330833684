/**
  颜色变量
**/
.robootAll_page {
  cursor: pointer;
}
.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
