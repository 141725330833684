/**
  颜色变量
**/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*::-webkit-scrollbar {
  width: 16px;
  height: 16px;
  background-color: #f5f5f5;
}
*::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(179, 191, 200, 0.6);
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #b3bfc8;
}
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #d1d9de6e;
  border-radius: 8px;
}
.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 12px;
  overflow-wrap: normal;
}
.btnPadding8 {
  padding: 4px 4px !important;
}
.btnPadding8 .ant-btn {
  padding: 4px 4px !important;
}
.btnPadding0 {
  padding: 0px 0px !important;
}
.btnPadding0 .ant-btn {
  padding: 0px 0px !important;
}
.btnPaddingRight {
  padding: 0px 4px 0px 0px !important;
}
.btnPaddingRight .ant-btn {
  padding: 0px 4px 0px 0px !important;
}
.btnPadding6 {
  padding: 0px 6px !important;
}
.btnPadding6 .ant-btn {
  padding: 0px 6px !important;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0;
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
}
.small_table_fixed .ant-table-cell-fix-left,
.small_table_fixed .ant-table-cell-fix-right {
  position: static !important;
}
.small_table_fixed .tabs_router .ant-tabs-content-holder {
  padding-right: 0px !important;
}
.large_table_fixed .ant-table-cell-fix-left,
.large_table_fixed .ant-table-cell-fix-right {
  position: sticky !important;
}
.large_table_fixed .tabs_router .ant-tabs-content-holder {
  padding-right: 16px !important;
}
