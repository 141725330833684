/**
  颜色变量
**/
.login_pages {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f0f2f5;
}
.login_pages .login_page_img {
  width: 100%;
  height: 220px;
  background-color: #096dd9;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url('./img/login_bg.png');
}
.login_pages .login_page_contain {
  width: 350px;
  height: 420px;
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-top: 10px solid #096dd9;
  box-shadow: 0 5px 10px #cccccc;
}
.login_pages .login_page_contain .login_page_logo {
  width: 56px;
  height: 56px;
  background-image: url('../../static/logo.png');
  background-size: 100% 100%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 15px;
}
.login_pages .login_page_contain .login_page_title {
  font-family: '微软雅黑';
  font-weight: 400;
  font-style: normal;
  color: #096dd9;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}
.login_pages .login_page_contain .login_page_checkout {
  width: 100%;
  text-align: right;
  color: #096dd9;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}
