/**
  颜色变量
**/
.arp_s_icon_modal {
  width: 100%;
  height: 450px;
  overflow-Y: auto;
}
.arp_s_icon_modal .arp_s_icon_modal_item {
  padding: 10px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.05);
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.5s;
}
.arp_s_icon_modal .arp_s_icon_modal_item:hover {
  background-color: #1890ff;
  color: #fff;
  transition: all 0.7s;
}
