/**
  颜色变量
**/
.layout_c_page {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.layout_c_page .breadcrumb_class {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  justify-content: center;
}
.layout_c_page .ant-dropdown-link {
  padding: 0 10px;
  font-size: 23px;
}
.layout_c_page .ant-dropdown-link:hover {
  cursor: pointer;
  background-color: rgba(0, 21, 41, 0.03);
}
.layout_c_page .ant-menu-sub.ant-menu-inline {
  background: #fff;
}
.layout_c_page #components-layout-demo-custom-trigger .trigger {
  font-size: 24px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}
.layout_c_page #components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
.layout_c_page .layoutLogo {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
}
.layout_c_page .layoutLogo .layoutLogo_img {
  width: 32px;
  height: 32px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    /*初始状态 透明度为0*/
  }
  50% {
    opacity: 0;
    /*中间状态 透明度为0*/
  }
  100% {
    opacity: 1;
    /*结尾状态 透明度为1*/
  }
}
.layout_c_page .layoutLogo .layoutLogo_name {
  font-size: 18px;
  font-weight: 600;
  color: #096dd9;
  margin-left: 12px;
  animation: fadeIn 0.2s;
}
.layout_c_page #components-layout-demo-custom-trigger .logo {
  height: 32px;
  width: 100%;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.layout_c_page .site-layout .site-layout-background {
  background: #fff;
}
.layout_c_page .bscsfcx {
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
}
.layout_c_page .bscsfcx:hover {
  background: rgba(0, 0, 0, 0.05);
}
.layout_c_page .tabs_class .ant-tabs {
  line-height: 2.5715 !important;
}
