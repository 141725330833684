/**
  颜色变量
**/
.tabs_router .ant-tabs-nav-list div {
  margin-right: 8px !important;
}
.tabs_router .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 12px;
}
.tabs_router .ant-tabs-content-holder {
  overflow: auto;
  max-height: calc(100vh - 180px);
  padding-right: 16px;
}
