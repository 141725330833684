/**
  颜色变量
**/
.down_box {
  min-width: 100px;
  margin-right: 16px;
}
.down_box .d_b_exothecium {
  padding: 6px 8px;
  background-color: #fafafa;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1;
  cursor: pointer;
}
.public_item {
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e8e8e8;
}
.table_box {
  display: flex;
  width: 100%;
}
.table_box .t_b_item_1 {
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e8e8e8;
  width: 8%;
  cursor: pointer;
  border-left: 1px solid #e8e8e8;
}
.table_box .t_b_item_2 {
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  width: 8%;
  border-right: 1px solid #e8e8e8;
}
.table_box .t_b_item_3 {
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  width: 35%;
  font-weight: 600;
  border-right: 1px solid #e8e8e8;
}
.table_box .t_b_item_4 {
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  width: 26%;
  border-right: 1px solid #e8e8e8;
}
.table_box .t_b_item_5 {
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  width: 15%;
  border-right: 1px solid #e8e8e8;
}
.table_box .t_b_item_6 {
  display: flex;
  padding: 8px 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  width: 14%;
  border-right: 1px solid #e8e8e8;
  cursor: pointer;
}
